import type { Options } from 'pro-gallery-lib';
import {
  PgLayoutImageCropTypeOptions,
  PgLayoutImageResizingModeOptions,
  isLayoutPGSideBySide,
  Layout,
  PgLayoutCardsRowTypeOptions,
  type VideoSettings,
} from '@wix/communities-blog-client-common';
import {
  HOVERING_BEHAVIOUR,
  CUBE_TYPE,
  TEXT_PLACEMENT,
  OPTION_GALLERY_SIZE_TYPE,
  TEXT_BOX_WIDTH_CALCULATION_OPTIONS,
  type LayoutImageRatio,
  type TextBoxAlignment,
} from '../../constants/pro-gallery-options';
import type { Color } from '../../types';

export type ProGalleryOptions = {
  containerWidth: number;
  isRTL: boolean;
  layoutAutoHeightEnabled: boolean;
  layoutCardsRowType: PgLayoutCardsRowTypeOptions;
  layoutContentHeight: number;
  layoutImageCropType: PgLayoutImageCropTypeOptions;
  layoutImageProportions: number;
  layoutImageRatio: LayoutImageRatio;
  layoutImageResizingMode: PgLayoutImageResizingModeOptions | undefined;
  layoutImageWidth: number;
  layoutPostSize: number;
  layoutPostsPerRow: number;
  layoutSpacing: number;
  textBoxAlignment: TextBoxAlignment;
  videoSettings: VideoSettings;
  hideCoverImage?: boolean;
  arrowsSize?: number;
  showArrows?: boolean;
  arrowsPosition?: number;
  arrowsColor?: string | Color;
  autoSlide?: boolean;
  pauseTime?: number;
  loop?: boolean;
};

export const getLayoutImageRatio = (
  layout: Layout | undefined,
  options: ProGalleryOptions,
  cropType: PgLayoutImageCropTypeOptions,
) => {
  if (options.hideCoverImage && options.layoutAutoHeightEnabled) {
    return 99;
  }

  if (`${cropType}` !== PgLayoutImageCropTypeOptions.Max) {
    return options.layoutImageRatio!;
  }

  if (isLayoutPGSideBySide(layout)) {
    return 4 / 3;
  }

  return 16 / 9;
};

type ItemBorder = {
  width: number;
  color?: string;
};

export const createOptions = (
  layout: Layout | undefined,
  options: ProGalleryOptions,
  itemBorder: ItemBorder,
): Options => {
  const galleryWidth = options.containerWidth - itemBorder.width;
  const imageMargin = -(galleryWidth > 0 ? itemBorder.width : itemBorder.width - 1 + galleryWidth);

  const commonGalleryOptions: Options = {
    stylingParams: {
      itemBorderWidth: itemBorder.width,
    },
    layoutParams: {
      targetItemSize: { unit: 'PIXEL', value: options.layoutPostSize },
      structure: { itemSpacing: options.layoutSpacing === 0 ? imageMargin : options.layoutSpacing },
      info: {
        layout: 'ATTACHED_BACKGROUND',
      },
    },
    behaviourParams: {
      gallery: { layoutDirection: options.isRTL ? 'RIGHT_TO_LEFT' : 'LEFT_TO_RIGHT' },
      item: {
        overlay: { hoverAnimation: 'NO_EFFECT' },
        content: { loader: 'BLUR' },
        video: {
          playTrigger:
            options.videoSettings.play === 'hover'
              ? 'HOVER'
              : options.videoSettings.play === 'onClick'
              ? 'CLICK'
              : 'AUTO',
          enablePlayButton: options.videoSettings.showPlayButton,
          loop: options.videoSettings.loop,
          speed: Number(options.videoSettings.playbackSpeed),
        },
        clickAction: 'NOTHING',
      },
    },
    // ---- Legacy options
    gallerySizeType: OPTION_GALLERY_SIZE_TYPE.PX,
    gallerySizePx: options.layoutPostSize,
    itemBorderColor: itemBorder.color,
    imageMargin: options.layoutSpacing === 0 ? imageMargin : options.layoutSpacing,
    isRTL: options.isRTL,
    allowTitle: true,
  };

  const cardsRowOptions: Options =
    options.layoutCardsRowType === PgLayoutCardsRowTypeOptions.ItemsPerRow &&
    options.layoutPostsPerRow
      ? {
          layoutParams: {
            ...commonGalleryOptions.layoutParams,
            structure: {
              ...commonGalleryOptions.layoutParams?.structure,
              numberOfColumns: options.layoutPostsPerRow,
              responsiveMode: 'SET_ITEMS_PER_ROW',
            },
          },
          // ---- Legacy options
          gridStyle: 1,
          numberOfImagesPerRow: options.layoutPostsPerRow,
        }
      : {};

  const cubeRatio = getLayoutImageRatio(layout, options, options.layoutImageCropType);
  const cubeTypeAndCubeRatio: Options =
    `${options.layoutImageCropType}` === PgLayoutImageCropTypeOptions.Max
      ? {
          layoutParams: {
            ...commonGalleryOptions.layoutParams,
            crop: {
              method: 'MAX',
              ratios: [cubeRatio],
            },
          },
        }
      : {
          layoutParams: {
            ...commonGalleryOptions.layoutParams,
            crop: {
              method: 'FILL',
              ratios: [cubeRatio],
            },
          },
        };

  const textBoxOptions =
    options.layoutImageResizingMode === PgLayoutImageResizingModeOptions.Fixed
      ? {
          layoutParams: {
            ...commonGalleryOptions.layoutParams,
            info: {
              ...commonGalleryOptions.layoutParams?.info,
              width: options.layoutPostSize - options.layoutImageWidth,
              sizeUnits: 'PIXEL',
            },
          },
          // ---- Legacy options
          textBoxWidth: options.layoutPostSize - options.layoutImageWidth,
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.MANUAL,
        }
      : {
          layoutParams: {
            ...commonGalleryOptions.layoutParams,
            info: {
              ...commonGalleryOptions.layoutParams?.info,
              width: Math.abs(options.layoutImageProportions - 100),
              sizeUnits: 'PERCENT',
            },
          },
          // ---- Legacy options
          textBoxWidthPercent: Math.abs(options.layoutImageProportions - 100),
          calculateTextBoxWidthMode: TEXT_BOX_WIDTH_CALCULATION_OPTIONS.PERCENT,
        };

  switch (layout) {
    case Layout.PgTextOnImageSmall:
    case Layout.PgTextOnImageMedium:
    case Layout.PgTextOnImageLarge:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cardsRowOptions.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            ...cardsRowOptions.layoutParams?.structure,
            galleryLayout: 2,
            groupsOrder: 'LEFT_TO_RIGHT',
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            placement: 'OVERLAY',
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'ALWAYS_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 2,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NO_CHANGE,
        titlePlacement: TEXT_PLACEMENT.SHOW_ON_HOVER,

        // ---- Not migrated yet
        cubeType: CUBE_TYPE.FILL,
        cubeRatio,
      } as Options;
    case Layout.PgSideBySide:
    case Layout.PgSideBySideRight:
      return {
        ...commonGalleryOptions,
        ...textBoxOptions,
        ...cubeTypeAndCubeRatio,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cubeTypeAndCubeRatio.layoutParams,
          ...textBoxOptions.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            galleryLayout: 2,
            groupsOrder: 'LEFT_TO_RIGHT',
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            ...textBoxOptions.layoutParams?.info,
            placement: options.textBoxAlignment
              ? options.textBoxAlignment === 'SHOW_ON_THE_LEFT'
                ? 'LEFT'
                : 'RIGHT'
              : null,
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'NEVER_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 2,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: options.textBoxAlignment,
        numberOfImagesPerRow: 1,
        gridStyle: 1,

        // ---- Not migrated yet
        cubeRatio,
      } as Options;
    case Layout.PgCardMedium:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cardsRowOptions.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            ...cardsRowOptions.layoutParams?.structure,
            galleryLayout: 1,
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            placement: 'BELOW',
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'NEVER_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 1,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW,

        // ---- Not migrated yet
        isVertical: true,
        textBoxHeight: options.layoutContentHeight,
      } as Options;
    case Layout.PgOneColumn:
      return {
        ...commonGalleryOptions,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            galleryLayout: 2,
            groupsOrder: 'LEFT_TO_RIGHT',
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            placement: 'BELOW',
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'NEVER_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 2,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW,

        // ---- Not migrated yet
        isVertical: true,
        cubeType: CUBE_TYPE.MIN,
        cubeRatio,
        textBoxHeight: options.layoutContentHeight,
      } as Options;
    case Layout.Slider:
      return {
        ...commonGalleryOptions,
        ...cubeTypeAndCubeRatio,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cubeTypeAndCubeRatio.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            galleryLayout: 5,
          },
          navigationArrows: {
            verticalAlignment: 'IMAGE_CENTER',
          },
        },
        // ---- Legacy options
        galleryLayout: 5,

        // ---- Not migrated yet
        isVertical: true,
        cubeRatio,
        showArrows: options.showArrows,
        slideshowInfoSize: options.layoutContentHeight,
        arrowsSize: options.arrowsSize,
        isAutoSlideshow: options.autoSlide,
        autoSlideshowInterval: options.pauseTime,
        autoSlideshowType: 'interval',
        arrowsPosition: options.arrowsPosition,
        arrowsColor:
          typeof options.arrowsColor === 'string'
            ? options.arrowsColor
            : options.arrowsColor?.value,
        slideshowLoop: options.loop,
      } as Options;
    case Layout.List:
    case Layout.ListMedium:
    case Layout.ListLarge:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        ...textBoxOptions,
        ...cubeTypeAndCubeRatio,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cardsRowOptions.layoutParams,
          ...cubeTypeAndCubeRatio.layoutParams,
          ...textBoxOptions.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            ...cardsRowOptions.layoutParams?.structure,
            galleryLayout: 2,
            groupsOrder: 'LEFT_TO_RIGHT',
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            ...textBoxOptions.layoutParams?.info,
            placement: 'BELOW',
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'NEVER_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 2,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: `SHOW_BELOW,${options.textBoxAlignment}`,
        numberOfImagesPerRow: 1,

        // ---- Not migrated yet
        isVertical: true,
        cubeRatio,
        textBoxHeight: options.layoutContentHeight,
      } as Options;
    case Layout.PgGrid:
    case Layout.PgGridIntermediate:
    case Layout.PgGridLarge:
    default:
      return {
        ...commonGalleryOptions,
        ...cardsRowOptions,
        ...cubeTypeAndCubeRatio,
        layoutParams: {
          ...commonGalleryOptions.layoutParams,
          ...cardsRowOptions.layoutParams,
          ...cubeTypeAndCubeRatio.layoutParams,
          structure: {
            ...commonGalleryOptions.layoutParams?.structure,
            ...cardsRowOptions.layoutParams?.structure,
            galleryLayout: 2,
            groupsOrder: 'LEFT_TO_RIGHT',
          },
          info: {
            ...commonGalleryOptions.layoutParams?.info,
            placement: 'BELOW',
          },
        },
        behaviourParams: {
          ...commonGalleryOptions.behaviourParams,
          item: {
            ...commonGalleryOptions.behaviourParams?.item,
            overlay: {
              ...commonGalleryOptions.behaviourParams?.item?.overlay,
              hoveringBehaviour: 'NEVER_SHOW',
            },
          },
        },
        // ---- Legacy options
        galleryLayout: 2,
        hoveringBehaviour: HOVERING_BEHAVIOUR.NEVER_SHOW,
        titlePlacement: TEXT_PLACEMENT.SHOW_BELOW,

        // ---- Not migrated yet
        isVertical: true,
        cubeRatio,
        textBoxHeight: options.layoutContentHeight,
      } as Options;
  }
};

// link to pro-gallery playground: https://pro-gallery.surge.sh
